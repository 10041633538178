:root {
    --theme-light-blue: #7DA0CD;
    --theme-dark-blue: #536A88;
    --theme-dark-grey: #4B505A;
    --theme-light-grey: #757575;
    --theme-dark-beige: #D8D8D8;
    --theme-light-beige: #F4F4F4;
    --theme-font-size-h1: 2.25rem;
    --theme-font-size-h2: 2rem;
    --theme-font-size-h3: 1.5rem;
    --theme-font-size-h4: 1.3rem;
    --theme-font-size-h5: 1.2rem;
    --theme-font-size-p: 1rem;
}

h1 {
    font-size: var(--theme-font-size-h1);
    font-weight: 500;
    margin-top: 2rem;
}

h2 {
    font-size: var(--theme-font-size-h2);
    font-weight: 500;
    margin-top: 1.75rem;
}

h3 {
    font-size: var(--theme-font-size-h3);
    font-weight: 500;
    margin-top: 1.5rem;
}

h4 {
    font-size: var(--theme-font-size-h4);
}

h5 {
    font-size: var(--theme-font-size-h5);
}

p {
    font-size: var(--theme-font-size-p);
}