/* Styling needed for external hamburguer menu component*/

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 25px;
  height: 18px;
  left: 18px;
  top: 16px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background-color: var(--theme-light-beige);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-container {
  position: absolute;
  top: 0;
  left: 0;
}

.menu-container .bm-burger-button {
  position: absolute;
}

.menu-item {
  text-decoration: none;
  color: var(--theme-dark-grey);
  margin-bottom: 20px;
  cursor: pointer;
}

.menu-item img {
  margin-right: 10px;
  vertical-align: middle;
}

.back-button {
  margin-top: 15px;
  margin-left: 20px;
  cursor: pointer;
}
