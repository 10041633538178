button.basic-button {
  background-color: var(--theme-light-blue);
  border: none;
  color: white;
  cursor: pointer;
}

button.basic-button:hover {
  background-color: var(--theme-dark-blue);
}

.filter-button {
  margin-top: 30px;
  margin-left: 20px;
  font-size: 1.2rem;
  height: 40px;
 
  border-radius: 5px;
}
