.reports-filter {
    width: 200px;
    margin-left: 15px !important;
    margin-top: 20px !important;
}

.time-fields {
    width: 185px;
}

.minutes-field {
    margin-left: 15px !important;
}

.hidden {
    display: none;
    visibility: hidden;
}

.exclamation-parent {
    position: relative;
}

.exclamation-popup {
    background-color: #f47c94;
    color: white;
    position: absolute;
    z-index: 10;
    top: -50px;
    width: 200px;
    padding: 2px;
    text-align: center;
    left: -50%;
    border-radius: 5px;
}

.exclamation-distance {
    float: left;
}

.exclamation-popup p {
    font-size: 0.875rem;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-left: auto;
    margin-right: auto;
}

.exclamation-mark {
    margin-left: 5px;
    margin-top: 1px;
}

.high-priority-mark {
    display: flex;
    align-items: center;
}

.tasks-dropdown {
    margin-bottom: 25px;
    margin-top: 5px;
}