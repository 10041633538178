.report-popover {
  display: flex;
  width: 450px;
  height: 364px;
}

.map-image {
  width: 445px;
  height: 358px;
}

.center-img {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  object-fit: contain;
}

.image-box {
  display: flex;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
}

.image-box:hover {
  opacity: 0.8;
  background: rgb(230, 230, 230);
  transition-duration: 0.5s;
}
