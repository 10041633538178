.login-box {
  background: #f0f0f0;
  opacity: 0.94;
  border-radius: 4px;
  box-shadow: 0 1px 3px #00000029;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  left: 50%;
  max-height: 380px;
  max-width: 320px;
  padding: 20px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 60px 60px 40px 60px;
}

.login-header-image {
  display: flex;
  justify-content: center;
  margin: 14px 0;
  width: 100%;
}

.login-button {
  font-size: 22px;
  height: 55px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.login-background {
  background-image: url('../../assets/login_background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  left: 0;
  position: fixed;
  resize: both;
  top: 0;
  width: 100vw;
}

.login-input {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #757575;
  border-radius: 0;
  margin-bottom: 30px;
  width: 100%;
  height: 2.2em;
  outline: none;
}

.login-input::placeholder {
  color: black;
  font-size: 1.5em;
}
