.top-bar-container {
  background-color: var(--theme-light-blue);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 60px;
  padding-right: 20px;
  height: 50px;
  max-width: 100%;
  z-index: -1;
}

.top-bar-container h5 {
  font-weight: normal;
  color: white;
}

.top-bar-container h6 {
  font-size: inherit;
  font-weight: normal;
  color: white;
}
